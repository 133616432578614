<template>
  <v-dialog
    v-model="isOpen"
    width="370px"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="py-5"
      color="#f2f2f2"
    >
      <v-icon
        class="absolute-close"
        @click="$emit('update:is-open', false)"
      >
        {{ icons.mdiClose }}
      </v-icon>

      <v-card-text class="pt-8 pb-0 justify-center ">
        <v-card-title class="justify-center text-center pb-0">
          {{ $t('game.are_you_sure') }}
        </v-card-title>
        <!-- <v-card-subtitle class="justify-center text-center mt-4 pb-0 px-0">
          {{ $t('game.are_you_sure_msg') }}
        </v-card-subtitle> -->
      </v-card-text>

      <v-card-text class="py-0">
        <v-row class="my-5 px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-2 px-2 d-flex justify-center align-center"
          >
            <v-btn
              class="text-body-1 font-weight-bold text-capitalize"
              style="text-transform: initial !important;"
              color="primary"
              dark
              rounded
              large
              block
              @click="$emit('save')"
            >
              {{ $t('game.unjoin') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            class="py-2 px-2 d-flex justify-center align-center"
          >
            <v-btn
              class="text-body-1 font-weight-bold"
              style="text-transform: initial !important;"
              color="primary"
              dark
              rounded
              large
              block
              outlined
              @click="$emit('update:is-open', false)"
            >
              {{ $t('game.i_want_to_play') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'

export default {
  name: 'Joined',
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    closeTime: {
      type: Number,
      default: 10000,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { userData } = useCryptoJs()

    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    return {
      userData,
      computedIsOpen,

      // i18n
      t,

      // icons
      icons: {
        mdiClose,
        check: require('@/assets/images/svg/check-success.svg'),
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .absolute-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f2f2f2;
    color: #3f3f3f;
    border-radius: 50px !important;
    padding: 2px;
  }

  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }

  .no-bg-hover::before {
    background-color: transparent !important;
  }
</style>
