import { ref } from '@vue/composition-api'
import { getUserPaymentMethods } from '@api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'

export default function useGame() {
  const { decryptData } = useCryptoJs()
  const { t } = useUtils()

  const paymentMethods = ref([])

  const fetchPaymentMethods = async () => {
    try {
      const response = await getUserPaymentMethods()
      if (response.ok) {
        paymentMethods.value = decryptData(response.data)
        paymentMethods.value.unshift({
          id: 0,
          last_4: null,
          exp_month: null,
          exp_year: null,
          brand: 'Add new card',
          exp_date_str: null,
        })
      }
    } catch (e) {
      console.error(e)
      paymentMethods.value = []
    }
  }

  const mapTeams = (playersA, playersB, team = null) => {
    if (!playersA || !playersA || playersA.length === 0 || playersB.length === 0) return []
    const teams = [
      {
        id: 1,
        players: playersA,
        my_team: team === 'A',
        name: `${t('game.team')} A`,
      },
      {
        id: 2,
        players: playersB,
        my_team: team === 'B',
        name: `${t('game.team')} B`,
      },
    ]

    return teams
  }

  return {
    // data
    paymentMethods,

    // mEthods
    fetchPaymentMethods,
    mapTeams,
  }
}
