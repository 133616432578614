import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const pack = require('./package.json')

const themeConfig = {
  app: {
    name: 'FANATY',
    logo: require('@/assets/images/logos/logo-2.png'),
    logoTitle: require('@/assets/images/logos/logo-title.png'),
    logoTitleBlack: require('@/assets/images/logos/logo-title-black.png'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'bordered', // default, bordered, semi-dark
    contentWidth: 'boxed',
    version: pack.version, // 1.0.12
  },
  menu: {
    isMenuHidden: true,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'hidden', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#3F3F3F', // '#979797', // '#FF3232' '#9155FD',
      accent: '#0D6EFD',
      secondary: '#FF3232', // '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',

      red: '#F44336',
      orange: '#FF9800',
      yellow: '#FFEB3B',
      green: '#4CAF50',
      blue: '#2196F3',
      purple: '#9C27B0',
      black: '#000000',
      darkGray: '#1D1D1D',
      lightGray: '#9F9F9F',
      white: '#FFFFFF',
      textSecondary: '#979797',
      lighterGray: '#AAAAAA',

      paid: '#13CE66',
      hold: '#00A6FF',
      pending: '#FFD185',
      cancelled: '#FF3232',
    },
    dark: {
      primary: '#333333', // #FF3232',
      accent: '#0d6efd',
      secondary: '#FF3232',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',

      red: '#F44336',
      orange: '#FF9800',
      yellow: '#FFEB3B',
      green: '#4CAF50',
      blue: '#2196F3',
      purple: '#9C27B0',
      black: '#000000',
      darkGray: '#1D1D1D',
      lightGray: '#9F9F9F',
      white: '#FFFFFF',
      textSecondary: '#979797',
      lighterGray: '#AAAAAA',

      paid: '#13CE66',
      hold: '#00A6FF',
      pending: '#FFD185',
      cancelled: '#FF3232',
    },
  },
}

export default themeConfig
