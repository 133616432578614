import Vue from 'vue'

export const show = message => {
  Vue.toasted.show(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 5000,
    action: [
      {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    ],
  })
}

export const showSuccess = message => {
  Vue.toasted.show(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 5000,
    className: 'toasted-show-success',
    icon: {
      name: 'check',
      after: false,
    },
    action: [
      {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    ],
  })
}

export const showError = message => {
  Vue.toasted.show(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 5000,
    className: 'toasted-show-error',
    icon: {
      name: 'close',
      after: false,
    },
    action: [
      {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    ],
  })
}

export const showInfo = message => {
  Vue.toasted.show(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 60000,
    className: 'toasted-show-info',
    icon: {
      name: 'information',
      after: false,
    },
    action: [
      {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    ],
  })
}

export const showWarning = message => {
  Vue.toasted.show(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 60000,
    className: 'toasted-show-warning',
    icon: {
      name: 'alert',
      after: false,
    },
    action: [
      {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    ],
  })
}

export const success = message => {
  Vue.toasted.success(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    keepOnHover: true,
    duration: 5000,
    action: [
      {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    ],
  })
}

export const info = message => {
  Vue.toasted.info(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    keepOnHover: true,
    duration: 5000,
    action: [
      {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    ],
  })
}

export const error = message => {
  Vue.toasted.error(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    keepOnHover: true,
    duration: 5000,
    action: [
      {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    ],
  })
}
