/* eslint-disable import/no-cycle */
import { apiHttp } from '../axiosApi'
import {
  landingReservation,
  landingReservationCancel,
  landingReservationHoldCard,
  reservationCode,
  reservationOpenCode,
  reservationPay,
} from '../config/apiRoute'

export const getReservation = code => apiHttp('GET', `${landingReservation}${code}`)
export const putCancelReservation = code => apiHttp('PUT', `${landingReservationCancel}${code}`)
export const putHoldCardReservation = (code, body) => apiHttp('PUT', `${landingReservationHoldCard}${code}`, body)

export const getReservationCode = async code => apiHttp(
  'GET',
  `${reservationCode}${code}`,
  {},
  {},
  true,
  'v2',
)
export const getReservationOpenCode = async code => apiHttp(
  'GET',
  `${reservationOpenCode}${code}`,
  {},
  {},
  true,
  'v2',
)
export const getReservationPay = async (code, body) => apiHttp(
  'PUT',
  `${reservationPay}${code}`,
  body,
  {},
  true,
  'v2',
)
