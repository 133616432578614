<template>
  <div>
    <v-radio-group
      v-model="selectedRadio"
      class="ma-0"
    >
      <v-row>
        <v-col
          cols="12"
          class="py-2 px-3"
        >
          <v-card
            width="100%"
            rounded="lg"
            class="cursor-pointer"
            :color="selectedRadio === 1 ? '#14ae5c40' : 'white'"
            :style="selectedRadio === 1 ? 'border: 2px solid #14ae5c !important;' : ''"
            @click="selectedRadio = 1"
          >
            <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
              <div style="width: 100%;">
                <div class="d-flex flex-column">
                  <span class="d-flex justify-space-between">
                    <span class="text-body-1">
                      <span class="font-weight-bold">{{ $t('game.pay_all') }}</span> ({{ formatCurrency(total) }})
                    </span>
                    <v-radio
                      :key="1"
                      :value="1"
                      color="#14ae5c"
                    ></v-radio>
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          class="py-2 px-3"
        >
          <v-card
            width="100%"
            rounded="lg"
            class="cursor-pointer"
            :color="selectedRadio === 2 ? '#14ae5c40' : 'white'"
            :style="selectedRadio === 2 ? 'border: 2px solid #14ae5c !important;' : ''"
            :disabled="disabled"
            @click="selectedRadio = 2"
          >
            <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
              <div style="width: 100%;">
                <div class="d-flex flex-column">
                  <span class="d-flex justify-space-between">
                    <span class="text-body-1">
                      <span class="font-weight-bold">{{ $t('game.split_price_participants') }}</span> ({{ formatCurrency(playerAmount) }})
                    </span>
                    <v-radio
                      :key="2"
                      :value="2"
                      color="#14ae5c"
                    ></v-radio>
                  </span>

                  <v-divider class="my-3" />

                  <span class="d-flex justify-space-between">
                    <span
                      class="text-body-1"
                      style="color: #3f3f3f;"
                    >
                      {{ $t('game.at_least') }} <span class="font-weight-bold black--text">{{ playerQty }}</span> {{ $t('game.participants') }}
                    </span>
                    <v-btn
                      small
                      text
                      color="black"
                      class="text-body-1 font-weight-bold"
                      style="text-decoration: underline; text-transform: initial;"
                      @click="$emit('splitParticipants')"
                    >
                      {{ $t('tooltip.edit') }}
                    </v-btn>
                  </span>

                  <span
                    class="mt-1"
                    style="color: #3f3f3f;"
                  >
                    <v-icon color="info">
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                    {{ $t('game.temporarily_hold') }} {{ formatCurrency(total) }}. {{ $t('game.if_fewer_than') }} {{ playerQty }} {{ $t('game.participants_pay_by') }} {{ date }} {{ time }}, {{ $t('game.covered_from') }} {{ playerQty }}, {{ $t('game.everyone_will_have') }}.
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-radio-group>
  </div>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import { computed, useSlots } from '@vue/composition-api'
import { formatCurrency, formatStdDate } from '@core/utils'

export default {
  name: 'ActionCard',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    playerAmount: {
      type: Number,
      default: 0,
    },
    playerQty: {
      type: Number,
      default: 0,
    },
    date: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const slots = useSlots()

    const selectedRadio = computed({
      get: () => props.selected,
      set: value => emit('updateSelected', value),
    })
    const hasIconContent = computed(() => !!slots.icon)

    return {
      hasIconContent,
      selectedRadio,

      formatCurrency,
      formatStdDate,

      icons: {
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.selected-card {
  border: 1px solid #14ae5c !important;
  background-color: rgba(20, 174, 92, 0.25) !important;
}
</style>
