<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3"
      >
        <v-card
          color="white"
          width="100%"
          rounded="lg"
          @click="$emit('open')"
        >
          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text pa-0 pb-5">
            <v-carousel
              height="205px"
              width="auto"
              cycle
              class="rounded-lg rounded-b-0"
              :show-arrows="false"
              hide-delimiter-background
              hide-delimiters
            >
              <div
                v-if="images.length === 0"
                style="
                  background-color: #3f3f3f;
                  width: 100%;
                  height: 100%;
                "
              >
                <img
                  :src="icons.play"
                  height="55px"
                  width="55px"
                  alt="play"
                  attrs="play"
                  style="background-position: center center;"
                  class="absolute-play"
                />
              </div>

              <div
                v-else
                class="relative"
              >
                <v-carousel-item
                  v-for=" (item, index) in images"
                  :key="index"
                  :src="item"
                  eager
                >
                </v-carousel-item>

                <img
                  :src="icons.play"
                  height="55px"
                  width="55px"
                  alt="play"
                  attrs="play"
                  style="background-position: center center;"
                  class="absolute-play"
                />
              </div>
            </v-carousel>
          </v-card-text>

          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
            <div class="d-flex">
              <div>
                <span class="font-weight-bold">{{ $t('video_app.watch_video') }}</span>
              </div>
            </div>

            <v-icon
              v-if="showArrow"
              color="#9b9b9b"
              class="ma-0"
              size="25"
            >
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiVideoOutline,
  mdiMessageText,
  mdiSeal,
  mdiChevronRight,
  mdiInformationOutline,
} from '@mdi/js'
import { computed, useSlots } from '@vue/composition-api'

export default {
  name: 'VideoCard',
  props: {
    showArrow: {
      type: Boolean,
      default: true,
    },
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup() {
    const slots = useSlots()

    const hasIconContent = computed(() => !!slots.icon)

    return {
      hasIconContent,

      icons: {
        mdiVideoOutline,
        mdiMessageText,
        mdiSeal,
        mdiChevronRight,
        mdiInformationOutline,
        play: require('@/assets/images/svg/play.svg'),
      },
    }
  },
}
</script>

<style>
  .absolute-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
