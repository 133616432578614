<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <!-- <v-card
        flat
        class="mt-5"
      > -->
      <v-form ref="form">
        <div class="px-3">
          <v-card-text class="pt-5">
            <v-row>
              <!-- sm="8"
              md="6" -->
              <v-col
                cols="12"
                class="align-self-end"
              >
                <!-- current password -->
                <v-text-field
                  v-model="currentPassword"
                  :type="isCurrentPasswordVisible ? 'text' : 'password'"
                  :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  :label="t('users.current_password')"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                ></v-text-field>

                <!-- new password -->
                <v-text-field
                  v-model="newPassword"
                  :type="isNewPasswordVisible ? 'text' : 'password'"
                  :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  :label="t('users.new_password')"
                  outlined
                  dense
                  :hint="t('users.password_hint')"
                  persistent-hint
                  class="mt-3"
                  :rules="[validators.required]"
                  @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                ></v-text-field>

                <!-- confirm password -->
                <v-text-field
                  v-model="cPassword"
                  :type="isCPasswordVisible ? 'text' : 'password'"
                  :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  :label="t('users.confirm_new_password')"
                  outlined
                  dense
                  class="mt-2"
                  :rules="[validators.required, validators.confirmedValidator(cPassword, newPassword)]"
                  @click:append="isCPasswordVisible = !isCPasswordVisible"
                ></v-text-field>
              </v-col>

              <!-- <v-col
                cols="12"
                sm="4"
                md="6"
                class="d-none d-sm-flex justify-center position-relative"
              >
                <v-img
                  contain
                  max-width="170"
                  src="@/assets/images/3d-characters/pose-m-1.png"
                  class="security-character"
                ></v-img>
              </v-col> -->
            </v-row>
          </v-card-text>
          <!-- action buttons -->
          <v-card-text class="d-flex justify-center">
            <v-btn
              color="secondary"
              class="me-3 mt-3"
              @click="changePass"
            >
              {{ t('settings.change_password') }}
            </v-btn>
            <v-btn
              color="primary"
              outlined
              class="mt-3"
              @click="cancelAction"
            >
              {{ t('tooltip.back') }}
            </v-btn>
          </v-card-text>
        </div>
      </v-form>
      <!-- </v-card> -->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { changePassword } from '@api'
import { error, success } from '@core/utils/toasted'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { required, confirmedValidator } from '@core/utils/validation'
import useCryptoJs from '@/@core/utils/useCryptoJs'

export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref(null)
    const newPassword = ref(null)
    const cPassword = ref(null)
    const form = ref(null)
    const { router } = useRouter()
    const { userData } = useCryptoJs()
    const { t } = useUtils()

    const changePass = async () => {
      if (form.value.validate()) {
        const response = await changePassword(userData.value.username, currentPassword.value, newPassword.value)
        if (response.ok) {
          success(response.message)
          router.push('/')
        } else {
          error(response.message.text)
        }
      }
    }

    const cancelAction = () => {
      form.value.reset()
      router.go(-1)
    }

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      form,

      changePass,
      cancelAction,

      validators: {
        required,
        confirmedValidator,
      },

      // i18n
      t,

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
