<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-1 py-1">
            <v-col
              cols="12"
              class="py-1 px-3 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <h1 class="text-h6 font-weight-bold black--text">
                  {{ t('game.pay_my_part') }}
                </h1>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-0 no-bg-hover font-weight-bold text-body-1"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin()"
                >
                  {{ t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  :disabled="true"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text v-if="dataParams">
        <booking-card
          :title="dataParams.sport_name"
          :date="`${formatStdDate(dataParams.reservation_date, 'date', true, $i18n.locale)} ${formatStdDate(dataParams.start_time, 'time', true, $i18n.locale)} - ${formatStdDate(dataParams.end_time, 'time', true, $i18n.locale)}`"
          :facility-name="dataParams.facility_name"
          :field-name="dataParams.field_name"
          :total="dataParams.total_with_fanaty_fee"
          :subtotal="dataParams.total"
          :fee-taxes="dataParams.fanaty_fee"
        ></booking-card>
      </v-card-text>

      <v-card-text v-if="dataParams">
        <policy-card
          :title="$t('game.cancellation_policy')"
          :subtitle="`${$t('game.cancellation_policy_msg')} ${formatStdDate(dataParams.cancelation_deadline, 'monthtime', true, $i18n.locale)}`"
        >
        </policy-card>
      </v-card-text>

      <v-card-text v-if="false">
        <payment-method-card
          :title="$t('payment_methods.payment_method')"
          :card-number="'Visa 4242'"
          :card-date="'Exp. 12/2021 • Federico Blumberg'"
        >
        </payment-method-card>
      </v-card-text>

      <v-card-actions
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          @click="openUpdatePay(true)"
        >
          <span>{{ t('tooltip.continue') }}</span>
        </v-btn>
      </v-card-actions>

      <checkout-pay
        v-if="dataParams"
        :is-open.sync="isDialogPay"
        :taxes="dataParams.fanaty_fee ? dataParams.fanaty_fee : 0"
        :subtotal="dataParams.total"
        :total="dataParams.total_with_fanaty_fee"
        :total-format="'usd'"
        :payment-methods="paymentMethods"
        :has-user="false"
        :user-data="!!userData ? userData : false"
        @openClose="openUpdatePay"
        @save="onPay"
      />
    </div>
  </div>
</template>

<script>
import { mdiCreditCardOutline, mdiFoodAppleOutline, mdiCalendar } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { required, minLengthValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency, formatStdDate } from '@core/utils'
import themeConfig from '@themeConfig'
import useCryptoJs from '@core/utils/useCryptoJs'
import usePhone from '@core/utils/usePhone'
import useLogData from '@core/utils/useLogData'
import {
  getMatchUserCheckout, getMatchGameCode, getLandingUserPaymentMethods, addMatchUserPay,
} from '@api'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import BookingCard from '@/views/pay-booking/components/booking-card/BookingCard.vue'
import PolicyCard from '@/views/pay-booking/components/policy-card/PolicyCard.vue'
import PaymentMethodCard from '@/views/pay-booking/components/payment-method-card/PaymentMethodCard.vue'
import CheckoutPay from '@/views/video-checkout/components/CheckoutPay.vue'

export default {
  components: {
    AppBarUserAvatar,
    BookingCard,
    PolicyCard,
    PaymentMethodCard,
    CheckoutPay,
  },
  setup() {
    const { t } = useUtils()
    const { userData, encrypt, decryptData } = useCryptoJs()
    const { phoneCodeOptions } = usePhone()
    const { route, router } = useRouter()
    const { logData, updateLogData } = useLogData()

    const privateLanding = ref(null)
    const dataParams = ref(null)
    const slotSelected = ref(null)
    const isDialogPay = ref(false)
    const matchCode = ref(null)
    const paymentMethods = ref([])

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const onLogin = async () => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = matchCode.value
      logData.value.redirect = 'landing'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      paymentMethods.value = []
      privateLanding.value = false
    }

    const payOrderVideo = async body => {
      const encryptInfo = encrypt(body)
      const respPay = await addMatchUserPay(matchCode.value, { info: encryptInfo })
      if (respPay.ok) {
        router.replace({
          name: 'match',
          params: {
            code: matchCode.value,
            joined: true,
            joinedMessage: respPay.data.notify_new_slot,
            joinedFrom: 'pay-booking',
          },
        })
      }
    }

    const onPay = async cardInfo => {
      const body = {
        slot: slotSelected.value,
        amount: dataParams.value.total_with_fanaty_fee,
        payment_method_id: null,
        card_info: cardInfo,
      }
      await payOrderVideo(body)
    }

    onMounted(async () => {
      const userLogged = await JSON.parse(localStorage.getItem('userData'))
      const { code, selected } = route.value.params
      matchCode.value = code
      slotSelected.value = selected

      if (!userLogged) router.replace({ name: 'match', params: { code: matchCode.value, selected: slotSelected.value } })

      let response = null
      if (matchCode.value && userData.value) {
        response = await getMatchUserCheckout(matchCode.value)
        if (response.ok) dataParams.value = response.data

        if (!dataParams.value.can_checkout) router.replace({ name: 'match', params: { code: matchCode.value, selected: slotSelected.value } })

        const resp = await getLandingUserPaymentMethods()
        let paymentMethodsParams = []
        const newPaymentMethod = {
          id: 0,
          last_4: null,
          exp_month: null,
          exp_year: null,
          brand: 'Add new card',
          exp_date_str: null,
        }
        if (resp.ok && resp.data) {
          paymentMethodsParams = decryptData(resp.data)
          if (paymentMethodsParams.legth) {
            const finded = paymentMethodsParams.find(e => e.id === 0)
            if (!finded) paymentMethodsParams.unshift(newPaymentMethod)
          } else paymentMethodsParams.unshift(newPaymentMethod)
        } else {
          paymentMethodsParams.unshift(newPaymentMethod)
        }
        paymentMethods.value = paymentMethodsParams

        privateLanding.value = true
      } else {
        response = await getMatchGameCode(matchCode.value)
        if (response.ok) dataParams.value = response.data
        privateLanding.value = false
      }
    })

    return {
      privateLanding,
      dataParams,
      isDialogPay,
      matchCode,
      paymentMethods,
      userData,

      // computed
      phoneCodeOptions,

      // methods
      formatCurrency,
      onLogin,
      onLogout,
      openUpdatePay,
      payOrderVideo,
      onPay,
      formatStdDate,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      // validations
      validators: {
        required,
        minLengthValidator,
        integerValidator,
      },

      t,

      icons: {
        mdiCreditCardOutline,
        mdiFoodAppleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
</style>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>

<style lang="scss" scoped>
.v-input__append-inner {
  max-width: auto 0px;
}

.white-border {
  border: 2px solid #ffffff !important;
}

.app-content-container {
  padding: 0px !important;
}

.card-img {
  height: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
</style>
