<template>
  <v-dialog
    v-model="isOpen"
    width="350px"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="py-5"
      color="#f2f2f2"
    >
      <v-icon
        class="absolute-close"
        @click="$emit('update:is-open', false)"
      >
        {{ icons.mdiClose }}
      </v-icon>

      <v-card-text class="pt-8 pb-0">
        <v-card-title class="justify-center">
          {{ t('videos.download_app_fanaty') }}
        </v-card-title>
        <v-card-subtitle class="justify-center text-center pb-0">
          {{ t('videos.continue_download') }}
        </v-card-subtitle>
      </v-card-text>

      <v-card-text class="py-0">
        <v-row class="my-5 px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <v-btn
              class="text-xs text-capitalize"
              color="primary"
              rounded
              large
              dark
              block
              @click="onSave"
            >
              {{ t('videos.download_app') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="ma-0 mx-2 px-0 py-5"
          style="borderTop: 1px solid #9b9b9b"
        >
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <h3
              class="text-sm font-weight-regular"
              style="color: #000000 !important;"
            >
              {{ t('videos.already_have') }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <a
              class="text-mail font-weight-bold"
              @click="onSave"
            >
              {{ t('videos.open_app') }}
            </a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'

export default {
  name: 'DownloadApp',
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    closeTime: {
      type: Number,
      default: 10000,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { userData } = useCryptoJs()

    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    const onSave = () => {
      emit('save')
    }

    watch([computedIsOpen], () => {
      if (computedIsOpen.value) {
        setTimeout(() => {
          computedIsOpen.value = false
        }, props.closeTime)
      }
    })

    return {
      // data
      userData,

      // computed
      computedIsOpen,

      // methods
      onSave,

      // i18n
      t,

      // icons
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .absolute-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f2f2f2;
    color: #3f3f3f;
    border-radius: 50px !important;
    padding: 2px;
  }

  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }

  .no-bg-hover::before {
    background-color: transparent !important;
  }
</style>
