<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3"
      >
        <v-card
          color="white"
          width="100%"
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
            <div class="d-flex">
              <span class="text-body-1">{{ myTeamName }}</span>
            </div>
            <div class="d-flex align-center">
              <span
                class="font-weight-bold text-h6"
                style="color: #3f3f3f;"
              >
                {{ myTeamResult ? myTeamResult : '?' }}
              </span>
              <span class="font-weight-bold text-h6 mx-3">-</span>
              <span
                class="font-weight-bold text-h6"
                style="color: #3f3f3f;"
              >
                {{ rivalTeamResult ? rivalTeamResult : '?' }}
              </span>
            </div>

            <div class="d-flex">
              <span class="text-body-1">{{ rivalTeamName }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiVideoOutline,
  mdiMessageText,
  mdiSeal,
  mdiChevronRight,
} from '@mdi/js'
import { computed, useSlots } from '@vue/composition-api'

export default {
  name: 'ResultsCard',
  props: {
    myTeamName: {
      type: String,
      default: '',
      required: true,
    },
    rivalTeamName: {
      type: String,
      default: '',
      required: true,
    },
    myTeamResult: {
      type: Number,
      default: 0,
      required: true,
    },
    rivalTeamResult: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  setup() {
    const slots = useSlots()

    const hasIconContent = computed(() => !!slots.icon)

    return {
      hasIconContent,

      icons: {
        mdiVideoOutline,
        mdiMessageText,
        mdiSeal,
        mdiChevronRight,
      },
    }
  },
}
</script>
