// Users
export const userLogin = '/user_console/login/'
export const userLogout = '/user/logout/'
export const userConsoleRefresh = '/auth/refresh/app_landing/'
export const userRegister = '/user/app_landing/register/'
export const userUpdate = '/user/app_landing/register/update/'
export const userResetPassword = '/user_console/reset_password/'
export const userChangePassword = '/user_console/change_password/'
export const userSendCodeSMS = '/user/send_access_sms/'
export const userSendCodeEmail = '/user/send_access_code_email/'
export const userSendConfirmationEmail = '/user/send_confirmation_code_email/'
export const userCheckVerificationEmail = '/user/check_verification_email/'
export const userCheckVerificationSMS = '/user/check_verification_sms/'
export const userActivity = '/landing_app/user_activity/'
export const userDeleteAccount = '/landing_app/user/deletion_request/'

// Match
export const matchPublic = '/match/public/'
export const matchPrivate = '/match/private/'
export const matchOpenCode = '/match/open/code/'
export const matchCode = '/match/code/'
export const matchGameOpenCode = '/landing/match/open/code/'
export const matchGameCode = '/landing/match/code/'
export const matchUserCheckout = '/landing/match/user/checkout/'
export const matchUserPay = '/landing/match/user/pay/'
export const matchUserLeave = '/landing/match/user/leave/'
export const matchPlayersQty = '/landing/match/players_qty/'

// landing
export const landingVerify = '/user/app_landing/verify/'
export const landingLogin = '/user/app_landing/login/'
export const landingJoinGame = '/landing_app/join/'
export const landingPayVideo = '/landing_app/pay_video/'
export const landingPayPremiumVideo = '/landing_app/pay_premium_video/'
export const landingRecordings = '/landing_app/recordings/open/'
export const landingRecordingsHashid = '/landing_app/recording/hashid/'
export const landingVideoOrder = '/video_order/landing_app/field/date/'

// Reservation
export const landingReservation = '/landing_app/reservation/open/code/'
export const landingReservationCancel = '/landing_app/reservation/open/cancel/'
export const landingReservationHoldCard = '/landing_app/reservation/hold_card/'
export const reservationOpenCode = '/landing/reservation/open/code/'
export const reservationCode = '/landing/reservation/code/'
export const reservationPay = '/landing/reservation/pay/'

export const playerRemove = '/landing_app/remove_player/'
export const playerPaid = '/landing_app/player_paid/'
export const playerLeave = '/landing_app/leave/'
export const guestApp = '/landing_app/add_guest/'
export const guestRemove = '/landing_app/remove_guest/'
export const matchInfo = '/landing_app/match_info/'
export const sendReportEmail = '/landing_app/send_report_email/'

// payment
export const landingPayment = '/landing_app/pay_video/'
export const landingPaymentMethod = '/landing_app/payment_method/'
export const paymentIntentStripe = '/landing_app/payment_intent/'
export const paymentIntentUpdateStripe = '/landing_app/payment_intent/add_customer/'
export const paymentOrderPremiumVideo = '/landing_app/order_premium_video/'
export const userPaymentMethods = '/landing_app/user/payment_methods/'
export const landingUserPaymentMethods = '/landing/user/payment_methods/'

// Circle
export const circleInvitation = '/circle/invitation_url/'

// Facility
export const facilityActive = '/facility/all/active/'
export const facilityPath = '/facility/'
export const facilitylocation = '/facility/mobile/active/location/'

// Field
export const fieldAll = '/field/all/'
export const fieldByFacility = '/field/facility/'

// Videos
export const videoPath = '/video/'
export const videoStartTime = '/video/start_time/'
