<template>
  <v-card
    v-if="teams.length"
    color="white"
    width="100%"
    rounded="lg"
  >
    <v-item-group
      v-model="slotSelected"
      mandatory
    >
      <v-container>
        <v-card-text
          v-for="(team, indexTeams) in teams"
          :key="`team-${indexTeams}`"
          :class="{'pt-4': indexTeams === 0}"
        >
          <v-row class="px-0 py-1">
            <v-col
              cols="12"
              class="pa-1 d-flex justify-space-between align-center"
            >
              <span class="font-weight-bold black--text">{{ team.name }}</span>

              <v-btn
                v-if="showEdit"
                class="pa-0 text-end no-bg-hover mr-1 text-body-1 font-weight-bold black--text"
                rounded
                text
                style="
                  text-transform: inherit;
                  min-width: 70px;
                "
                :ripple="false"
                @click="$emit('edit-players')"
              >
                {{ t('tooltip.edit') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            v-for="(player, index) in team.players"
            :id="`player-${player.slot}`"
            :key="`player-${player.slot}`"
            class="pa-0 pt-3 ma-0"
            :class="{ 'b-bottom': index !== team.length - 1, 'pt-4': index === 0 }"
          >
            <v-item
              v-slot="{ active, toggle }"
              :value="player.slot"
              :class="{'cursor-pointer': player.empty && !disabled }"
            >
              <v-col
                cols="12"
                class="pa-0 d-flex justify-space-between align-center"
                @click="() => { player.empty && !disabled ? toggle() : null }"
              >
                <div class="d-flex align-center justify-space-between">
                  <v-avatar
                    color="white"
                    :class="{ 'v-avatar-light-bg primary--text': !player.empty }"
                    :style="player.its_me ? { border: '2px solid #14AE5C !important' } : active ? { border: '1.5px solid #14AE5C !important', backgroundColor: '#14ae5c40 !important' } : player.empty ? { border: '1.5px dashed #3f3f3f !important' } : {}"
                    size="50"
                  >
                    <v-img
                      v-if="player.avatar"
                      :src="player.avatar"
                    ></v-img>
                    <span
                      v-else-if="player.fullname"
                      class="font-weight-medium"
                    >{{ avatarText(player.fullname) }}</span>
                    <v-icon
                      v-else
                      :color="active ? '#14AE5C' : '#3f3f3f'"
                      class="ma-0"
                      size="25"
                    >
                      {{ active ? icons.mdiCheck : icons.mdiPlus }}
                    </v-icon>
                  </v-avatar>

                  <div class="d-flex flex-column ml-2 justify-center">
                    <span
                      :class="{ 'black--text': !player.empty, 'font-weight-bold': player.empty && active }"
                    >
                      {{ player.fullname ? player.fullname : active ? $t('game.slot_selected') : $t('game.slot_empty') }}
                    </span>

                    <span
                      v-if="player.is_organizer === 'Y'"
                      class="text-xs"
                    >
                      {{ t('player_actions.organizer') }}
                    </span>

                    <!-- <div>
                      <div class="d-flex justify-start align-center">
                        <v-btn
                          v-if="isPrivate && (isPlayer || isOrganizer) && player.paid === 'Y'"
                          class="pa-0 text-center no-bg-hover mr-1 text-xs justify-start"
                          :class="{ 'cursor-default': !isOrganizer }"
                          style="
                            text-transform: inherit;
                            color: #77D353;
                            min-width: auto;
                            height: 25px !important;
                          "
                          text
                          :ripple="false"
                          @click="onPayPlayer('N', player.slot, isOrganizer)"
                        >
                          {{ t('player_actions.paid') }}
                        </v-btn>

                        <v-btn
                          v-if="isPrivate && isOrganizer && player.paid === 'N'"
                          class="pa-0 text-center no-bg-hover mr-1 text-xs justify-start"
                          style="
                            text-transform: inherit;
                            color: #FF3232;
                            min-width: 60px;
                            height: 25px !important;
                          "
                          text
                          :ripple="false"
                          @click="onPayPlayer('Y', player.slot, isOrganizer)"
                        >
                          {{ t('player_actions.mark_as_paid') }}
                        </v-btn>

                        <span
                          v-if="isPrivate && (player.organizer || player.guest) && (((isPlayer || isOrganizer) && player.paid === 'Y') || (isOrganizer && player.paid === 'N'))"
                          class="text-xs pr-1"
                        >
                          •
                        </span>

                        <span
                          v-if="player.organizer"
                          class="text-xs"
                        >
                          {{ t('player_actions.organizer') }}
                        </span>

                        <span
                          v-if="player.guest"
                          class="text-xs"
                        >
                          {{ t('add_guest.guest') }}
                        </span>
                      </div>
                    </div> -->
                  </div>
                </div>

                <div v-if="!player.empty">
                  <v-chip>
                    <img
                      v-if="player.paid === 'Y'"
                      :src="icons.moneyBill"
                      height="20px"
                      width="20px"
                      alt="moneyBill"
                      attrs="moneyBill"
                      style="background-position: center center; margin-right: 8px"
                    />
                    <span
                      class="text-sm"
                      :class="{ 'font-weight-bold': player.paid === 'Y' }"
                      :style="player.paid === 'N' ? { color: '#9b9b9b' } : {}"
                    >
                      {{ player.paid === 'Y' ? t('status.paid') : t('status.not_paid') }}
                    </span>
                  </v-chip>
                </div>

                <div v-if="showActions && false">
                  <player-action
                    v-if="false"
                    :show-remove="player.show_remove"
                    :show-leave="player.show_leave"
                    :show-add="player.show_add"
                    @add-guest="openCloseAddGuest(player.slot)"
                    @remove-player="onRemovePlayer(player.user_id, player.guest, player.slot)"
                    @leave-player="onLeave"
                  />

                  <v-chip v-if="player.showActions">
                    <v-icon
                      class="mr-2"
                      color="#FFBF00"
                    >
                      {{ icons.mdiSeal }}
                    </v-icon>
                    <span class="text-medium">
                      {{ player.seal }}
                    </span>
                  </v-chip>
                </div>
              </v-col>
            </v-item>
          </v-row>

          <v-divider
            v-if="indexTeams !== teams.length - 1"
            class="mt-7 mb-5"
            style="border-color: #9B9B9B"
          />
        </v-card-text>
      </v-container>
    </v-item-group>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import {
  removePlayer, paidPlayer, editMatchInfo, leavePlayer, addGuest, removeGuest,
} from '@api'
import {
  mdiPlus,
  mdiSeal,
  mdiCheck,
} from '@mdi/js'
import PlayerAction from '@/views/landing/components/player-action/PlayerAction.vue'

export default {
  name: 'PlayerList',
  components: {
    PlayerAction,
  },
  props: {
    teams: {
      type: Array,
      default: () => [],
      required: true,
    },
    playerQuantity: {
      type: Number,
      default: 0,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    isPlayer: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Number,
      default: 0,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const slotSelected = computed({
      get: () => props.selected,
      set: value => emit('update-selected', value),
    })
    const isDialogChangePlayers = ref(false)
    const isDialogAddGuest = ref(false)
    const dataGuest = ref({
      guest_phone_number: null,
      guest_name: null,
    })

    const computedMatchCode = computed(() => JSON.parse(localStorage.getItem('matchCode')))
    const computedTitle = computed(() => props.title)

    const openClose = val => {
      isDialogChangePlayers.value = val
    }

    const onChangeQuantity = async val => {
      const resp = await editMatchInfo(computedMatchCode.value, { match_players_qty: val })
      if (resp.ok) {
        isDialogChangePlayers.value = false
        emit('refresh-match')
      }
    }

    const openCloseAddGuest = val => {
      isDialogAddGuest.value = true
      dataGuest.value.slot = val
    }

    const onAddGuest = async val => {
      dataGuest.value.guest_name = val.guest_name
      dataGuest.value.guest_phone_number = val.guest_phone_number
      dataGuest.value.match_code = computedMatchCode.value

      const resp = await addGuest(dataGuest.value)
      if (resp.ok) {
        isDialogAddGuest.value = false
        emit('refresh-match')
      }
    }

    const onLeave = async () => {
      const resp = await leavePlayer(computedMatchCode.value)
      if (resp.ok) emit('refresh-match')
    }

    const onRemovePlayer = async (user, guest, slot) => {
      let resp = null
      if (guest) resp = await removeGuest(computedMatchCode.value, slot)
      else resp = await removePlayer(computedMatchCode.value, user)

      if (resp.ok) emit('refresh-match')
    }

    const onPayPlayer = async (paid, slot, isOrganizer) => {
      if (isOrganizer) {
        const resp = await paidPlayer(computedMatchCode.value, {
          match_player_paid: paid,
          slot,
        })

        if (resp.ok) emit('refresh-match')
      }
    }

    return {
      // data
      isDialogChangePlayers,
      isDialogAddGuest,
      slotSelected,

      // computed
      computedTitle,

      // methods
      openClose,
      onChangeQuantity,
      openCloseAddGuest,
      onAddGuest,
      onRemovePlayer,
      onPayPlayer,
      onLeave,
      avatarText,

      // i18n
      t,

      icons: {
        mdiPlus,
        mdiSeal,
        mdiCheck,
        moneyBill: require('@/assets/images/svg/money-bill.svg'),
      },
    }
  },
}
</script>
