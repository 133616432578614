<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-1 py-1">
            <v-col
              cols="12"
              class="py-1 px-3 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <h1 class="text-h6 font-weight-bold black--text">
                  {{ t('game.pay_booking') }}
                </h1>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-0 no-bg-hover font-weight-bold text-body-1"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin()"
                >
                  {{ t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text
        v-if="dataParams"
        class="pt-0"
      >
        <v-card-title class="text-body-1 font-weight-bold black--text px-0">
          <span>1. {{ $t('game.review_details') }}</span>
        </v-card-title>

        <booking-card
          :title="dataParams.sport_name"
          :date="`${formatStdDate(dataParams.reservation_date, 'date', true, $i18n.locale)} ${formatStdDate(dataParams.start_time, 'time', true, $i18n.locale)} - ${formatStdDate(dataParams.end_time, 'time', true, $i18n.locale)}`"
          :facility-name="dataParams.facility_name"
          :field-name="dataParams.field_name"
          :total="dataParams.total_price_with_fanaty_fee"
          :subtotal="dataParams.reservation_base_price"
          :fee-taxes="dataParams.service_fee_taxes"
        ></booking-card>
      </v-card-text>

      <v-card-text
        v-if="dataParams"
        class="pt-0"
      >
        <policy-card
          :no-title="true"
        >
          <template #message>
            <span>
              <span class="font-weight-bold">{{ $t('game.cancellation_policy_msg_short') }}</span>
              {{ `${$t('game.before')} ${formatStdDate(dataParams.cancelation_deadline, 'monthtime', true, $i18n.locale)}` }}</span>
          </template>
        </policy-card>
      </v-card-text>

      <v-card-text
        v-if="dataParams"
        class="pt-0"
        style="padding-bottom: 140px;"
      >
        <v-card-title class="text-body-1 font-weight-bold black--text px-0 pt-0">
          2. {{ $t('game.how_to_pay') }}
        </v-card-title>

        <pay-card
          :selected="paymentType"
          :player-amount="dataParams.players_final_amount"
          :player-qty="dataParams.players_qty"
          :total="dataParams.total_price_with_fanaty_fee"
          :date="`${formatStdDate(dataParams.cancelation_deadline, 'day', true, $i18n.locale)} ${$t('game.of')} ${formatStdDate(dataParams.cancelation_deadline, 'month-str', true, $i18n.locale)}`"
          :time="formatStdDate(dataParams.end_time, 'time', true, $i18n.locale)"
          :disabled="dataParams && dataParams.can_split_payment && !dataParams.is_organizer"
          @updateSelected="(val) => paymentType = val"
          @splitParticipants="openUpdateSplit(true)"
        ></pay-card>
      </v-card-text>

      <v-card-text v-if="false">
        <payment-method-card
          :title="$t('payment_methods.payment_method')"
          :card-number="'Visa 4242'"
          :card-date="'Exp. 12/2021 • Federico Blumberg'"
        >
        </payment-method-card>
      </v-card-text>

      <v-card-actions
        v-if="!userLogged || (!!userLogged && dataParams && dataParams.is_organizer)"
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          @click="!!userLogged ? openUpdatePay(true) : onLogin(true)"
        >
          <span>{{ t('tooltip.continue') }}</span>
        </v-btn>
      </v-card-actions>

      <checkout-pay
        v-if="dataParams"
        :is-open.sync="isDialogPay"
        :taxes="dataParams.fanaty_fee ? dataParams.fanaty_fee : 0"
        :subtotal="dataParams.total"
        :total="dataParams.total_with_fanaty_fee"
        :total-format="'usd'"
        :payment-methods="paymentMethods"
        :has-user="false"
        :user-data="!!userData ? userData : false"
        @openClose="openUpdatePay"
        @save="onPay"
      />

      <split-participants
        v-if="dataParams"
        :is-open.sync="isDialogSplit"
        :options="dataParams.split_payment_amounts ? dataParams.split_payment_amounts : []"
        :selected="selectedSplit"
        :max-players="dataParams.max_players_qty"
        @updateSelected="(val) => selectedSplit = val"
        @openClose="openUpdateSplit"
        @save="onChangePlayersQty"
      />
    </div>
  </div>
</template>

<script>
import { mdiCreditCardOutline, mdiFoodAppleOutline, mdiCalendar } from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { required, minLengthValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency, formatStdDate } from '@core/utils'
import themeConfig from '@themeConfig'
import useCryptoJs from '@core/utils/useCryptoJs'
import usePhone from '@core/utils/usePhone'
import useLogData from '@core/utils/useLogData'
import {
  getLandingUserPaymentMethods,
  getReservationPay,
  getReservationCode,
  getReservationOpenCode,
  changeMatchPlayersQty,
} from '@api'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import BookingCard from '@/views/pay-booking/components/booking-card/BookingCard.vue'
import PolicyCard from '@/views/pay-booking/components/policy-card/PolicyCard.vue'
import PaymentMethodCard from '@/views/pay-booking/components/payment-method-card/PaymentMethodCard.vue'
import CheckoutPay from '@/views/video-checkout/components/CheckoutPay.vue'
import SplitParticipants from '@/views/reservation/components/split-participants/SplitParticipants.vue'

import PayCard from '@/views/reservation/components/pay-card/PayCard.vue'

export default {
  components: {
    AppBarUserAvatar,
    BookingCard,
    PolicyCard,
    PaymentMethodCard,
    CheckoutPay,
    PayCard,
    SplitParticipants,
  },
  setup() {
    const { t } = useUtils()
    const { userData, encrypt, decryptData } = useCryptoJs()
    const { phoneCodeOptions } = usePhone()
    const { route, router } = useRouter()
    const { logData, updateLogData } = useLogData()

    const privateLanding = ref(null)
    const dataParams = ref(null)
    const isDialogPay = ref(false)
    const isDialogSplit = ref(false)
    const matchCode = ref(null)
    const reservationCode = ref(null)
    const paymentMethods = ref([])
    const paymentType = ref(1)
    const selectedSplit = ref(-1)
    const userLogged = ref(null)

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const openUpdateSplit = async val => {
      isDialogSplit.value = val
    }

    const onLogin = async (showPay = false) => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = matchCode.value
      logData.value.reservationCode = reservationCode.value
      logData.value.redirect = 'reservation'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'
      logData.value.showPay = showPay
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const payOrderVideo = async body => {
      const encryptInfo = encrypt(body)
      const respPay = await getReservationPay(reservationCode.value, { info: encryptInfo })
      if (respPay && respPay.ok) {
        router.replace({
          name: 'match',
          params: {
            code: matchCode.value,
            joined: true,
            joinedMessage: respPay.data.notify_new_slot,
            joinedFrom: 'reservation',
          },
        })
      }
    }

    const onPay = async cardInfo => {
      const body = {
        amount: dataParams.value.total_price_with_fanaty_fee,
        payment_method_id: null,
        payment_type: paymentType.value === 1 ? 'full' : 'per_player',
        card_info: cardInfo,
      }
      await payOrderVideo(body)
    }

    const initReservation = async () => {
      selectedSplit.value = -1
      userLogged.value = await JSON.parse(localStorage.getItem('userData'))
      privateLanding.value = !!userLogged.value
      const { code, showPay } = route.value.params

      reservationCode.value = code

      let response = null
      let responsePaymentMethods = null
      if (userLogged.value) {
        response = await getReservationCode(reservationCode.value)
        responsePaymentMethods = await getLandingUserPaymentMethods()
      } else response = await getReservationOpenCode(reservationCode.value)

      if (response && response.ok) {
        dataParams.value = response.data
        matchCode.value = dataParams.value.match_code

        if (!!userLogged && dataParams.value && dataParams.value.is_organizer && showPay) openUpdatePay(showPay)

        if (!dataParams.value.can_checkout) router.replace({ name: 'match', params: { code: dataParams.value.match_code } })

        let paymentMethodsParams = []
        const newPaymentMethod = {
          id: 0,
          last_4: null,
          exp_month: null,
          exp_year: null,
          brand: 'Add new card',
          exp_date_str: null,
        }
        if (responsePaymentMethods && responsePaymentMethods.ok && responsePaymentMethods.data) {
          paymentMethodsParams = decryptData(responsePaymentMethods.data)
          if (paymentMethodsParams.legth) {
            const finded = paymentMethodsParams.find(e => e.id === 0)
            if (!finded) paymentMethodsParams.unshift(newPaymentMethod)
          } else paymentMethodsParams.unshift(newPaymentMethod)
        } else {
          paymentMethodsParams.unshift(newPaymentMethod)
        }
        paymentMethods.value = paymentMethodsParams
      }
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      paymentMethods.value = []
      privateLanding.value = false

      await initReservation()
    }

    const onChangePlayersQty = async playersQty => {
      await changeMatchPlayersQty(matchCode.value, {
        players_qty: playersQty,
      })
      openUpdateSplit(false)
      await initReservation()
    }

    watch([isDialogSplit], () => {
      if (isDialogSplit.value) selectedSplit.value = -1
    })

    onMounted(async () => {
      await initReservation()
    })

    return {
      privateLanding,
      dataParams,
      isDialogPay,
      isDialogSplit,
      matchCode,
      paymentMethods,
      userData,
      selectedSplit,
      paymentType,
      userLogged,

      // computed
      phoneCodeOptions,

      // methods
      formatCurrency,
      onLogin,
      onLogout,
      openUpdatePay,
      openUpdateSplit,
      payOrderVideo,
      onPay,
      formatStdDate,
      onChangePlayersQty,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      // validations
      validators: {
        required,
        minLengthValidator,
        integerValidator,
      },

      t,

      icons: {
        mdiCreditCardOutline,
        mdiFoodAppleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
</style>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>

<style lang="scss" scoped>
.v-input__append-inner {
  max-width: auto 0px;
}

.white-border {
  border: 2px solid #ffffff !important;
}

.app-content-container {
  padding: 0px !important;
}

.card-img {
  height: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
</style>
