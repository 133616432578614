<template>
  <div
    id="video-not-found"
    class="bg-video-not-found"
  >
    <div class="d-flex align-center justify-center logo-video-not-found">
      <v-img
        :src="appLogo"
        max-height="40px"
        max-width="40px"
        alt="logo"
        contain
        class="me-3 "
      ></v-img>
      <span
        class="font-weight-bold text-xl"
        style="color: black !important; font-family: 'Orbitron';"
      >
        {{ appName }}
      </span>
    </div>
    <div class="header-video-app d-flex justify-center">
      <div class="d-flex align-center">
        <v-btn
          class="text-sm pa-0 ma-0"
          style="text-transform: inherit;"
          text
          @click="defaultDownloadAppByOS"
        >
          <span class="white--text text-sm">
            {{ t('videos.dont_have_fanaty') }} <strong>{{ t('videos.get_app') }}</strong>
          </span>
        </v-btn>
      </div>
    </div>
    <div
      class="auth-wrapper auth-v1"
    >
      <div
        class="auth-inner card-width"
      >
        <v-row
          class="d-flex justify-center"
          style="margin-bottom: 100px !important;"
        >
          <v-col
            cols="12"
            md="12"
            sm="10"
            class="align-self-start pa-0"
          >
            <v-card
              color="transparent"
              class="rounded-lg d-flex align-center"
              elevation="15"
              rounded="15"
              min-height="300"
            >
              <v-card-text class="pa-3">
                <div>
                  <div
                    class="ms-0 width-full"
                  >
                    <v-row class="pa-1 d-flex justify-center">
                      <v-col
                        cols="12"
                        class="d-flex justify-center align-center mb-1"
                      >
                        <div class="d-flex align-center justify-center pt-6">
                          <v-avatar
                            class="delete-icon"
                            size="90"
                          >
                            <v-icon
                              class="pa-5"
                              color="error"
                            >
                              {{ icons.mdiDeleteForever }}
                            </v-icon>
                          </v-avatar>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="justify-center mt-3 mb-0">
                      <p class="ma-0 text-xl black--text font-weight-bold">
                        {{ t('video_app.match_deleted') }}
                      </p>
                    </v-row>
                    <v-row class="justify-center mb-3 mt-0">
                      <p
                        class="ma-0 text-sm"
                        style="letter-spacing: 2px;"
                      >
                        {{ t('video_app.content_not_exist') }}
                      </p>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref } from '@vue/composition-api'
import { useRouter, defaultDownloadAppByOS } from '@core/utils'
import { mdiDeleteForever } from '@mdi/js'

// import { getFieldApp } from '@api'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'

export default {
  setup() {
    const fieldData = ref({
      end_playback_time: null,
      facility_logo: null,
      facility_name: null,
      field_name: null,
      highlight_name: null,
      highlight_owner: null,
      highlight_owner_avatar: null,
      share_user: null,
      share_user_avatar: null,
      start_playback_time: null,
      thumbnails: null,
      video_date: null,
      video_id: null,
      video_link: null,
      video_long: null,
      video_thumbnail: null,
      video_time: null,
      video_type: null,
    })
    const fieldRef = ref(null)
    const { t } = useUtils()
    const { router } = useRouter()

    const goTo = () => {
      router.push('/')
    }

    return {
      fieldData,
      fieldRef,

      // methods
      defaultDownloadAppByOS,
      avatarText,
      goTo,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // i18n
      t,

      icons: {
        mdiDeleteForever,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style scope>
.img-h {
  width: 100% !important;
  height: 56vh !important;
}

.card-width {
  width: 50rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }

  .card-width {
    width: 20rem !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }

  .card-width {
    width: 20rem !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .img-h {
    width: 100% !important;
    height: 50vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}

#video-not-found {
  font-family: 'Open Sans' !important;
}

.bg-video-not-found {
  background-color: #f2f2f2;
}

.header-video-not-found {
  background-color: #ff3232;
  min-height: 6vh;
}

.header-video-app {
  background-color: #ff3232;
  min-height: 6vh;
}

.red {
  color: #ff3232;
}

.logo-video-not-found {
  background-color: #f2f2f2;
  min-height: 14vh;
}

.btn-video-not-found {
  min-height: 12vh;
}

.auth-wrapper {
  min-height: calc(var(--vh, 1vh) * 80);
}

.img-h .v-responsive__content {
  width: 100% !important;
}

.img-h .v-responsive__sizer {
  padding: 0 !important;
}

.delete-icon {
  background-color: #9b9b9b;
}
</style>
