<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-bottom-transition"
    class="pay-dialog"
    content-class="height-mid"
    overlay-color="black"
    overlay-opacity="0.8"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="pa-3"
      rounded
      style="width: 100%; border-radius: 10px 10px 0 0 !important;"
      color="#f2f2f2"
    >
      <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)"
        dense
        class="px-0 text-start"
      >
        <v-toolbar-title class="text-h6 font-weight-bold black--text pl-0">
          <span>{{ $t('game.divide_by_at_least') }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="close-btn"
          icon
          small
          style="background-color: #f2f2f2"
          @click="$emit('update:is-open', false)"
        >
          <v-icon color="#3f3f3f">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text class="px-2">
        <v-radio-group
          v-model="computedSelected"
          class="ma-0"
        >
          <v-row>
            <v-col
              v-for="(option, index) in options"
              :key="index"
              cols="12"
              class="py-2 px-3"
            >
              <v-card
                width="100%"
                rounded="lg"
                class="cursor-pointer"
                :color="computedSelected === option.players_qty ? '#14ae5c40' : 'white'"
                :style="computedSelected === option.players_qty ? 'border: 2px solid #14ae5c !important;' : ''"
                @click="computedSelected = option.players_qty"
              >
                <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
                  <div style="width: 100%;">
                    <div class="d-flex flex-column">
                      <span class="d-flex justify-space-between">
                        <span class="text-body-1">
                          <span class="font-weight-bold">{{ option.players_qty }} {{ $t('game.participants') }}</span> ({{ formatCurrency(option.total_price) }})
                        </span>
                        <v-radio
                          :key="option.players_qty"
                          :value="option.players_qty"
                          color="#14ae5c"
                        ></v-radio>
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              class="py-2 px-3"
            >
              <v-card
                width="100%"
                rounded="lg"
                class="cursor-pointer"
                :color="computedSelected === 0 ? '#14ae5c40' : 'white'"
                :style="computedSelected === 0 ? 'border: 2px solid #14ae5c !important;' : ''"
                @click="computedSelected = 0"
              >
                <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
                  <div style="width: 100%;">
                    <div class="d-flex flex-column">
                      <span class="d-flex justify-space-between align-center">
                        <v-text-field
                          ref="customPlayersQtyInput"
                          v-model="customPlayersQty"
                          class="mr-3"
                          :placeholder="$t('game.custom_players_quantity')"
                          solo
                          dende
                          hide-details
                          :disabled="computedSelected !== 0"
                          @keypress="onlyNumbers"
                        />
                        <v-radio
                          :key="0"
                          :value="0"
                          color="#14ae5c"
                          @click="computedSelected = 0"
                        ></v-radio>
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-card-text>

      <v-card-actions class="d-flex flex-column justify-center pt-0 pb-3 px-2">
        <v-btn
          block
          rounded
          large
          color="primary"
          type="submit"
          class="mt-6 text-capitalize text-body-1 font-weight-bold"
          style="height: 50px !important"
          @click="onContinue"
        >
          {{ $t('tooltip.continue') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { formatCurrency } from '@core/utils'
import { onlyNumbers } from '@/@core/utils/functionsHelpers'
import {
  between,
} from '@core/utils/validation'

export default {
  name: 'SplitParticipants',
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Number,
      default: -1,
      required: true,
    },
    maxPlayers: {
      type: Number,
      default: 999999999999,
    },
  },
  setup(props, { emit }) {
    const customPlayersQty = ref(null)
    const customPlayersQtyInput = ref(null)
    const computedSelected = computed({
      get: () => props.selected,
      set: value => emit('updateSelected', value),
    })
    const computedIsOpen = computed(() => props.isOpen)

    const onContinue = () => {
      if (computedSelected.value >= 0) {
        if (computedSelected.value !== 0) emit('save', computedSelected.value)
        else if (customPlayersQty.value) emit('save', customPlayersQty.value)
      }
    }

    watch([customPlayersQty], async () => {
      const valid = await between(customPlayersQty.value, 1, props.maxPlayers)()
      if (customPlayersQty.value && valid !== true) customPlayersQty.value = null
    })

    watch([computedSelected], () => {
      if (computedSelected.value !== 0) customPlayersQty.value = null
      if (computedSelected.value === 0) {
        setTimeout(() => {
          customPlayersQtyInput.value.focus()
        }, 200)
      }
    })

    return {
      customPlayersQty,
      customPlayersQtyInput,

      computedSelected,
      computedIsOpen,

      onContinue,
      formatCurrency,
      onlyNumbers,
      between,

      // icons
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
.height-mid {
  border-radius: 0;
  margin: auto auto 0;
  height: auto;
  width: 28rem !important;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  margin: 0 !important;
  margin-bottom: -2px !important;
  // left: 50%;
  // transform: translateX(-50%);
}

@media only screen and (max-width: 600px) {
  .height-mid {
    width: 100% !important;
  }
}

.b-top {
  border-radius: 10px 10px 0 0 !important;
}

.close-btn {
  background-color: #353535;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-block: auto;
}

.terms-and-policies {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.v-slide-group__next, .v-slide-group__prev {
  min-width: 30px !important;
}

</style>

<style lang="scss">
.selected-card {
  border: 1px solid #14ae5c !important;
  background-color: rgba(20, 174, 92, 0.25) !important;
}

.v-application.theme--light .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #ffffff;
}
</style>
