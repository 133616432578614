<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold black--text mb-2">
          {{ t('access_code.access_code') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('access_code.enter_code') }} {{ loginData.type === 'EMAIL' ? loginData.code : formatNumber(loginData.fullPhone) }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="loginSubmit"
        >
          <v-otp-input
            id="otp-input"
            v-model="accessCode"
            type="number"
            class="code-number ma-auto pl-3"
            :length="totalInput"
            @finish="loginSubmit"
          />

          <div class="d-flex justify-center align-center">
            <span class="mr-2">{{ t('access_code.didnt_receive') }}</span>
            <v-btn
              class="pa-0 text-center no-bg-hover"
              style="
                text-transform: inherit;
                color: #FF3232;
                min-width: 60px;
              "
              text
              :ripple="false"
              @click="onResend"
            >
              {{ t('tooltip.resend') }}
            </v-btn>
          </div>

          <v-btn
            block
            color="primary"
            type="submit"
            rounded
            large
            class="mt-6 text-capitalize"
            :disabled="!isFormValid"
          >
            {{ t('tooltip.continue') }}
          </v-btn>

          <div class="d-flex justify-center">
            <v-btn
              class="mt-1 py-2 no-bg-hover"
              color="primary"
              style="text-transform: inherit;"
              text
              @click="onChangeLogin"
            >
              {{ t('access_code.or_password') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, computed, getCurrentInstance, onMounted } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import store from '@/store'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import usePhone from '@core/utils/usePhone'
import { sendCodeSMSUser, sendCodeEmailUser } from '@api'
import router from '@/router'

export default {
  setup() {
    const { t } = useUtils()
    const { encrypt } = useCryptoJs()
    const { formatNumber } = usePhone()
    const { logData, currentLogData } = useLogData()
    const vm = getCurrentInstance().proxy

    const form = ref(null)
    const loginData = ref({
      code: '',
      phoneCode: '',
      fullPhone: '',
      type: '',
      exists: '',
      loginRoute: 'LOGIN_PAGE',
      password: null,
    })

    const totalInput = ref(4)
    const completed = ref(false)
    const accessCode = ref('')

    const isFormValid = computed(() => {
      if (accessCode.value && accessCode.value.length === totalInput.value) return true

      return false
    })

    const loginSubmit = async () => {
      if (accessCode.value.length === totalInput.value) {
        const body = {
          code: loginData.value.type === 'EMAIL' ? loginData.value.code : loginData.value.fullPhone,
          type: loginData.value.type,
          join_game: loginData.value.joinGame,
          match_code: loginData.value.matchCode,
          login_route: 'ACCESS_CODE_PAGE',
          password: accessCode.value,
        }
        const encryptBody = encrypt(body)

        const userAbility = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]
        vm.$ability.update(userAbility)
        store.dispatch('auth/loginUserAction', {
          data: encryptBody,
          redirect: logData.value.redirect,
          facility: logData.value.facility,
          field: logData.value.field,
          date: logData.value.date,
          time: logData.value.time,
          slot: logData.value.slot,
          pay: logData.value.pay,
          reservationCode: logData.value.reservationCode,
          showPay: logData.value.showPay,
        })
        setTimeout(() => {
          accessCode.value = ''
        }, 800)
      }
    }

    const onChangeLogin = () => {
      router.go(-1)
    }

    const onResend = async () => {
      accessCode.value = ''
      let info = null
      let encryptBody = null
      if (loginData.value.type === 'PHONE_NUMBER') {
        info = {
          phone_number: loginData.value.fullPhone,
        }
        encryptBody = encrypt(info)
        await sendCodeSMSUser(encryptBody)
      } else {
        info = {
          email: loginData.value.code,
        }
        encryptBody = encrypt(info)
        await sendCodeEmailUser(encryptBody)
      }
    }

    onMounted(async () => {
      Object.assign(logData.value, currentLogData.value)
      logData.value.loginRoute = 'LOGIN_PAGE'
      loginData.value = logData.value
    })

    return {
      form,
      loginData,
      isFormValid,

      totalInput,
      completed,
      accessCode,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      loginSubmit,
      onChangeLogin,
      onResend,
      formatNumber,

      t,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
@import '@core/preset/preset/pages/auth-code.scss';
</style>
