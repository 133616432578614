<template>
  <div class="d-flex align-center">
    <v-avatar
      color="#9B9B9B"
      :class="{'v-avatar-light-bg primary--text': facility.logo }"
      size="50"
    >
      <v-img
        v-if="facility.logo"
        :src="facility.logo"
      ></v-img>
      <span
        v-else
        class="font-weight-medium"
      >{{ avatarText(facility.name) }}</span>
    </v-avatar>
    <div class="d-flex flex-column ml-2 justify-center">
      <span
        class="black--text text-sm font-weight-medium black--text"
      >
        {{ facility.name }}
      </span>
      <span class="text-caption">{{ facility.complete_address }}</span>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'FacilityList',
  props: {
    facility: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()

    return {
      // methods
      avatarText,

      // i18n
      t,
    }
  },
}
</script>
