<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        v-bind="attrs"
        size="30px"
        style="background-color: #9B9B9B;"
        class="v-avatar-light-bg primary--text mr-1"
        v-on="!disabled ? on : null"
      >
        <v-img
          v-if="userData && userData.avatar"
          :src="userData.avatar"
        ></v-img>
        <v-icon
          v-else
          color="white"
          size="28"
        >
          {{ icons.mdiAccountOutline }}
        </v-icon>
      </v-avatar>
    </template>

    <v-list>
      <div
        v-if="userData && userData.avatar"
        class="pb-3 pt-2"
      >
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="35px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData.avatar"
              :src="userData.avatar"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.fullName || userData.username }}
          </span>
          <!-- <small class="text--disabled text-capitalize">{{ userData.user_role }}</small> -->
          <!-- <small class="text--disabled text-capitalize">{{ resolveUserRoleText(userData.role) }}</small> -->
        </div>
      </div>

      <v-divider></v-divider>

      <div v-if="userData && userData.avatar">
        <v-list-item :to="{ name: 'auth-reset-password' }">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLockReset }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ t('settings.change_password') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <v-list-item @click="logoutUser">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ t('settings.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider
          v-if="showReport"
          class="my-2"
        />
      </div>

      <div v-if="showReport">
        <v-list-item @click="$emit('report', true)">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiTextBoxEditOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ t('settings.report') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLogoutVariant,
  mdiLockReset,
  mdiTextBoxEditOutline,
} from '@mdi/js'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@/@core/utils/useCryptoJs'
import store from '@/store'

export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    showReport: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { t } = useUtils()
    const { userData } = useCryptoJs()

    const logoutUser = () => {
      vm.$ability.update(initialAbility)
      store.dispatch('auth/logoutAction')

      emit('logout')
    }

    const resolveUserRoleText = role => {
      if (role === 'subscriber' || role === 'F') return 'Facility'
      if (role === 'author' || role === 'R') return 'Representative'
      if (role === 'maintainer') return 'Maintainer'
      if (role === 'editor') return 'Editor'
      if (role === 'admin' || role === 'A') return 'Administrator'

      return 'Administrator'
    }

    return {
      userData,

      logoutUser,
      resolveUserRoleText,
      t,

      icons: {
        mdiAccountOutline,
        mdiLogoutVariant,
        mdiLockReset,
        mdiTextBoxEditOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
