<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3"
      >
        <v-card
          color="white"
          width="100%"
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
            <div style="width: 100%;">
              <div class="d-flex flex-column">
                <span class="d-flex justify-space-between">
                  <span class="text-body-1 font-weight-bold">{{ title }}</span>
                  <span class="text-body-1 font-weight-bold">{{ formatCurrency(subtotal) }}</span>
                </span>
                <span
                  class="mt-1"
                  style="color: #3f3f3f;"
                >
                  {{ date }}
                </span>
                <span style="color: #3f3f3f;">{{ facilityName }}</span>
                <span style="color: #3f3f3f;">{{ fieldName }}</span>
                <span class="d-flex justify-space-between mt-1">
                  <span class="text-body-1 font-weight-bold">{{ $t('reservations.video_recording_title') }}</span>
                  <span
                    class="text-body-1 font-weight-bold"
                    style="color: #14AE5C;"
                  >{{ $t('status.included') }}</span>
                </span>

                <v-divider class="my-3" />

                <span class="d-flex justify-space-between text-body-1">
                  <span>
                    {{ $t('reservations.service_fee_and_taxes') }}
                    <v-icon
                      size="20"
                      color="#3f3f3f"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </span>
                  <span class="text-body-1">{{ formatCurrency(feeTaxes) }}</span>
                </span>
                <span class="d-flex justify-space-between">
                  <span class="text-body-1 font-weight-bold">Total</span>
                  <span class="text-body-1 font-weight-bold">{{ formatCurrency(total) }}</span>
                </span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import { computed, useSlots } from '@vue/composition-api'
import { formatCurrency } from '@core/utils'

export default {
  name: 'ActionCard',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    subtotal: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    feeTaxes: {
      type: Number,
      default: 0,
    },
    facilityName: {
      type: String,
      default: '',
    },
    fieldName: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
  },
  setup() {
    const slots = useSlots()

    const hasIconContent = computed(() => !!slots.icon)

    return {
      hasIconContent,

      formatCurrency,

      icons: {
        mdiInformationOutline,
      },
    }
  },
}
</script>
