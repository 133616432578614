<template>
  <v-dialog
    v-model="isModalOpen"
    max-width="420px"
    persistent
  >
    <v-card
      class="pa-3 rounded-lg"
      rounded
      style="width: 100%"
      color="#f2f2f2"
    >
      <v-form ref="form">
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
          dense
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-h6 black--text pl-0">
            {{ t('landing.report_issue') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
            color="primary"
            @click="$emit('update:is-modal-open', false)"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-app-bar>

        <v-card-text class="mt-5 pb-3 px-2 justify-center d-flex flex-column">
          <v-text-field
            v-if="!userData"
            v-model="email"
            outlined
            :label="t('users.email')"
            :rules="[required, emailValidator]"
          ></v-text-field>

          <v-textarea
            v-model.number="issue"
            rows="6"
            auto-grow
            :placeholder="t('landing.describe_your_issue')"
            dense
            outlined
            rounded
            :rules="[required]"
          ></v-textarea>
        </v-card-text>

        <v-card-actions class="d-flex flex-column justify-center py-3 px-2">
          <v-btn
            class="text-capitalize"
            color="secondary"
            large
            rounded
            block
            dark
            @click="onSave"
          >
            {{ t('tooltip.submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import { ref, computed, watch } from '@vue/composition-api'
import { mdiPlus, mdiDeleteOutline, mdiClose, mdiMenuDown } from '@mdi/js'
import { required, integerValidator, emailValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'

export default {
  name: 'Report',
  model: {
    prop: 'isModalOpen',
    event: 'update:is-modal-open',
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    minPlayers: {
      type: Number,
      default: 12,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { userData } = useCryptoJs()
    const form = ref(null)
    const issue = ref(null)
    const email = ref(null)

    const computedIsModalOpen = computed(() => props.isModalOpen)
    const computedMinPlayers = computed(() => props.minPlayers)

    const validateMinPlayer = v => {
      if (v >= computedMinPlayers.value) return true

      return `Número de jugadores debe ser mayor o igual a ${computedMinPlayers.value}`
    }

    const onSave = () => {
      if (form.value.validate()) emit('report-issue', issue.value, userData.value ? userData.value.user_email : email.value)
    }

    watch([computedIsModalOpen], () => {
      if (!computedIsModalOpen.value) {
        issue.value = null
        email.value = null
        form.value.resetValidation()
      }
    })

    return {
      // data
      userData,
      form,
      issue,
      email,

      // computed
      computedIsModalOpen,
      computedMinPlayers,

      // methods
      onSave,

      // validations
      required,
      integerValidator,
      validateMinPlayer,
      emailValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiClose,
        mdiMenuDown,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }
</style>
