<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold black--text mb-2">
          {{ t('is_this_you.is_this_you') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('is_this_you.found_account') }}
        </p>
      </v-card-text>

      <v-card-text>
        <div class="d-flex justify-center">
          <v-avatar
            size="120"
            class="mx-auto"
          >
            <v-img :src="loginData.avatar"></v-img>
          </v-avatar>
        </div>
        <p class="font-weight-medium text--primary ma-0 mt-3 text-center">
          {{ loginData.fullname }}
        </p>
        <p class="text-xs ma-0 mt-1 text-center">
          {{ loginData.info }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="onYesNotAction('YES')"
        >
          <v-btn
            block
            color="secondary"
            rounded
            large
            type="submit"
            class="text-capitalize"
          >
            {{ t('is_this_you.yes') }}
          </v-btn>

          <div class="d-flex justify-center">
            <v-btn
              class="mt-1 py-2 no-bg-hover"
              style="text-transform: inherit; color: #979797;"
              text
              rounded
              large
              @click="onYesNotAction('NO')"
            >
              {{ t('is_this_you.no') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import { sendCodeSMSUser, sendConfirmationEmailUser } from '@api'

export default {
  setup() {
    const { t } = useUtils()
    const { router } = useRouter()
    const { encrypt } = useCryptoJs()
    const { logData, currentLogData, updateLogData } = useLogData()

    const form = ref(null)
    const loginData = ref({})

    const onYesNotAction = async response => {
      loginData.value.responseIsThisYou = response
      updateLogData(loginData.value)

      let respSend = null
      let info = null
      let encryptInfo = null
      if (loginData.value.typeIsThisYou === 'PHONE_NUMBER') {
        info = {
          phone_number: loginData.value.register.fullPhone,
        }
        encryptInfo = encrypt(info)
        respSend = await sendCodeSMSUser(encryptInfo)
      } else {
        info = {
          email: loginData.value.register.email,
        }
        encryptInfo = encrypt(info)
        respSend = await sendConfirmationEmailUser(encryptInfo)
      }
      if (respSend.ok) router.push({ name: 'auth-confirmation-code' })
    }

    onMounted(async () => {
      Object.assign(logData.value, currentLogData.value)
      logData.value.loginRoute = 'LOGIN_PAGE'
      loginData.value = logData.value
    })

    return {
      form,
      loginData,

      // themeConfig
      appLogo: themeConfig.app.logo,
      appLogoTitle: themeConfig.app.logoTitle,

      onYesNotAction,

      t,

      validators: {
        required,
        emailValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style lang="scss" scoped>
// .v-text-field--outlined > .v-input__control > .v-input__slot {
.v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
  height: 65px;
  max-height: 65px;
  min-width: 65px;
  text-align: center;
  align-items: center;

  input {
    font-size: 1.25rem;
  }
}
</style>
